<template>
  <div>
    <h2 class="mb-5">Acid-Base Titration: Data & Calculations</h2>

    <h3 class="mb-4">Data</h3>

    <h4 class="mb-3">Part A.</h4>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table1Caption"
        prefix="Table 1. "
        label="Enter table caption here"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table style="max-width: 606px">
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 35%">Property</td>
            <td style="font-weight: bold; text-align: center; width: 35%">Value</td>
            <td style="font-weight: bold; text-align: center; width: 30%">Unit</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Volume of NaOH added</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueA1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitA1" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Original [NaOH]</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueA2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitA2" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Volume of water added</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueA3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitA3" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <h4 class="mb-3 mt-8">Part B.</h4>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table2Caption"
        prefix="Table 2. "
        label="Enter table caption here"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table style="max-width: 606px">
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 35%">Property</td>
            <td style="font-weight: bold; text-align: center; width: 35%">Value</td>
            <td style="font-weight: bold; text-align: center; width: 30%">Unit</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Initial volume of water in 10 mL grad cyl</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueB1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitB1" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Final volume of water in 10 mL grad cyl</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueB2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitB2" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Number of drops</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueB3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">drops</td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <h4 class="mb-3 mt-8">Part C.</h4>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table3Caption"
        prefix="Table 3. "
        label="Enter table caption here"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 30%">Property</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 1</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 2</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 3</td>
            <td style="font-weight: bold; text-align: center; width: 16%">Unit</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{V}_\text{i}$" />
              in HCl buret
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC1T1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC1T2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC1T3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitC1" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{V}_\text{f}$" />
              in HCl buret
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC2T1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC2T2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC2T3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitC2" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{V}_\text{NaOH}$" />
              added to reach endpoint
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC3T1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC3T2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueC3T3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitC3" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <h4 class="mb-3 mt-8">Part D.</h4>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table4Caption"
        prefix="Table 4. "
        label="Enter table caption here"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 30%">Property</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 1</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 2</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 3</td>
            <td style="font-weight: bold; text-align: center; width: 16%">Unit</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Unknown Acid Number</td>
            <td colspan="3" class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.unknownAcidNumber"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">N/A</td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{V}_\text{i}$" />
              in Unknown Acid buret
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD1T1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD1T2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD1T3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitD1" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{V}_\text{f}$" />
              in Unknown Acid buret
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD2T1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD2T2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD2T3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitD2" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{V}_\text{NaOH}$" />
              added to reach endpoint
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD3T1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD3T2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.valueD3T3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <v-select v-model="inputs.unitD3" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <h3 class="mb-4">Calculations</h3>

    <p class="mb-3">
      Calculate the approximate concentration of the dilute NaOH solution you prepared using its
      initial concentration, initial volume and the final (total) volume of the solution and the
      equation
      <stemble-latex content="$\text{c}_1\text{V}_1=\text{c}_2\text{V}_2.$" />
      (Remember: The total amount (mol) is the same!) This is ONLY an approximate value as
      <stemble-latex content="$\text{c}_1$" />
      is ONLY given to 1 digit!
    </p>

    <calculation-input
      v-model="inputs.approxConcNaOH"
      class="mb-5"
      prepend-text="$\text{[NaOH]:}$"
      append-text="$\text{mol/L}$"
      :disabled="!allowEditing"
    />

    <p class="mb-3">
      What was the average drop volume you determined in the calibration of your drop counter?
    </p>

    <calculation-input
      v-model="inputs.averageDropVolume"
      class="mb-5"
      prepend-text="$\text{V}_\text{drop}:$"
      append-text="$\text{mL/drop}$"
      :disabled="!allowEditing"
    />

    <p class="mb-3">
      Please select and upload a titration curve for 1 of the titrations of HCl below. Also, please
      provide a Figure Heading & Caption in the text field below the upload field.
    </p>

    <p class="mb-n2">
      <v-file-input v-model="standardizationTitrationCurve" multiple accept="application/pdf" />
    </p>

    <p class="mb-3">
      <v-text-field v-model="inputs.titrationCurve1Caption" label="Enter title here" />
    </p>

    <p class="mb-3">
      Fill in the table caption below along with the data and subsequent calculations for the
      titration of HCl with NaOH.
    </p>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table5Caption"
        prefix="Table 5. "
        label="Enter table caption here"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 30%">Property</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 1</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 2</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 3</td>
            <td style="font-weight: bold; text-align: center; width: 16%">Unit</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{V}_\text{NaOH}$" />
              added to reach equivalence point
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.VeqT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.VeqT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.VeqT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">mL</td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$[\text{NaOH}]_\text{dil}$" />
              (based on equivalence point)
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concNaOHEqPointT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concNaOHEqPointT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concNaOHEqPointT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">mol/L</td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$[\text{NaOH}]_\text{dil}$" />
              (based on end point)
            </td>

            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concNaOHEndPointT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concNaOHEndPointT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concNaOHEndPointT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">mol/L</td>
          </tr>

          <tr>
            <td>
              Average
              <stemble-latex content="$[\text{NaOH}]_\text{dil}$" />
            </td>

            <td class="py-2 px-2 mx-0 centered-input" colspan="3">
              <calculation-input
                v-model="inputs.concNaOHAvg"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">mol/L</td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <p class="mb-3">
      Please select and upload a titration curve for 1 of the titrations of the unknown acid below.
      Also, please provide a Figure Heading & Caption in the text field below the upload field.
    </p>

    <p class="mb-n2">
      <v-file-input v-model="unknownTitrationCurve" multiple accept="application/pdf" />
    </p>

    <p class="mb-3">
      <v-text-field v-model="inputs.titrationCurve2Caption" label="Enter title here" />
    </p>

    <p class="mb-3">
      Fill in the table caption below along with the data (and subsequent calculated values) for the
      titration of the unknown acid.
    </p>

    <p class="mb-n1">
      <v-text-field
        v-model="inputs.table6Caption"
        prefix="Table 6. "
        label="Enter table caption here"
      />
    </p>

    <p class="pl-n8 mb-5">
      <v-simple-table>
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: left; width: 30%">Property</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 1</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 2</td>
            <td style="font-weight: bold; text-align: center; width: 18%">Trial 3</td>
            <td style="font-weight: bold; text-align: center; width: 16%">Unit</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{V}_\text{NaOH}$" />
              added to reach equivalence point
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.VeqUnknownT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.VeqUnknownT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.VeqUnknownT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">mL</td>
          </tr>
          <tr>
            <td>[Unknown Acid] based on equivalence point</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concUnknownEqT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concUnknownEqT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concUnknownEqT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">
              <v-select
                v-model="inputs.concUnknownEqUnit"
                :items="unitOptions"
                outlined
                hide-details
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>
          <tr>
            <td>[Unknown Acid] based on endpoint</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concUnknownEPT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concUnknownEPT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.concUnknownEPT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">
              <v-select
                v-model="inputs.concUnknownEPUnit"
                :items="unitOptions"
                outlined
                hide-details
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>Half-equivalence point volume</td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.halfVeqT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.halfVeqT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.halfVeqT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">
              <v-select v-model="inputs.halfVeqUnit" :items="unitOptions" outlined hide-details>
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{pK}_\text{a}$" />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.pKaT1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.pKaT2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0 centered-input">
              <calculation-input
                v-model="inputs.pKaT3"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">---</td>
          </tr>

          <tr>
            <td>
              Average
              <stemble-latex content="$\text{pK}_\text{a}$" />
            </td>

            <td class="py-2 px-2 mx-0 centered-input" colspan="3">
              <calculation-input
                v-model="inputs.pKaAvg"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 px-2 mx-0" style="text-align: center">---</td>
          </tr>
        </tbody>
      </v-simple-table>
    </p>

    <p class="mb-2 mt-8">
      Based on the data and calculations performed here, what is the identity of your unknown acid?
    </p>

    <v-select v-model="inputs.acidIdentity" :items="acidOptions" outlined hide-details class="mb-4">
      <template #item="{item}">
        <stemble-latex class="no-text-transform" :content="item" />
      </template>
      <template #selection="{item}">
        <stemble-latex class="no-text-transform" :content="item" />
      </template>
    </v-select>

    <p class="mb-3 mt-4">
      What is the % error with respect to the
      <stemble-latex content="$\text{pK}_\text{a}$" />
      value that you determined?
    </p>

    <calculation-input
      v-model="inputs.pctError"
      prepend-text="$\text{Percent Error:}$"
      append-text="$\%$"
      :disabled="!allowEditing"
    />
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'OttawaLab4ReportSheet',
  components: {
    CalculationInput,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        table1Caption: null,
        valueA1: null,
        unitA1: null,
        valueA2: null,
        unitA2: null,
        valueA3: null,
        unitA3: null,

        table2Caption: null,
        valueB1: null,
        unitB1: null,
        valueB2: null,
        unitB2: null,
        valueB3: null,
        unitB3: null,

        table3Caption: null,
        valueC1T1: null,
        valueC1T2: null,
        valueC1T3: null,
        unitC1: null,
        valueC2T1: null,
        valueC2T2: null,
        valueC2T3: null,
        unitC2: null,
        valueC3T1: null,
        valueC3T2: null,
        valueC3T3: null,
        unitC3: null,

        table4Caption: null,
        unknownAcidNumber: null,
        valueD1T1: null,
        valueD1T2: null,
        valueD1T3: null,
        unitD1: null,
        valueD2T1: null,
        valueD2T2: null,
        valueD2T3: null,
        unitD2: null,
        valueD3T1: null,
        valueD3T2: null,
        valueD3T3: null,
        unitD3: null,

        approxConcNaOH: null,
        averageDropVolume: null,

        titrationCurve1Caption: null,
        titrationCurve2Caption: null,

        VeqT1: null,
        concNaOHEqPointT1: null,
        concNaOHEndPointT1: null,

        VeqT2: null,
        concNaOHEqPointT2: null,
        concNaOHEndPointT2: null,

        VeqT3: null,
        concNaOHEqPointT3: null,
        concNaOHEndPointT3: null,

        VeqUnknownT1: null,
        concUnknownEqT1: null,
        concUnknownEPT1: null,
        halfVeqT1: null,
        pKaT1: null,

        VeqUnknownT2: null,
        concUnknownEqT2: null,
        concUnknownEPT2: null,
        halfVeqT2: null,
        pKaT2: null,

        VeqUnknownT3: null,
        concUnknownEqT3: null,
        concUnknownEPT3: null,
        halfVeqT3: null,
        pKaT3: null,

        concUnknownEqUnit: null,
        concUnknownEPUnit: null,
        halfVeqUnit: null,

        pctError: null,
        acidIdentity: null,

        concNaOHAvg: null,
        pKaAvg: null,

        table5Caption: null,
        table6Caption: null,
      },
      unitOptions: [
        '°C',
        'g',
        'g/L',
        'mL/drop',
        'kg',
        'mL',
        'mol/L',
        'drop/mL',
        'g/mL',
        'K',
        'mol/kg',
        'drops/s',
        'min',
      ],
      acidOptions: [
        'Acetic acid',
        'Potassium acid phthalate (KHP)',
        'Lactic acid',
        'Ammonium chloride',
      ],
      standardizationTitrationCurve: [],
      unknownTitrationCurve: [],
    };
  },
  computed: {
    attachments() {
      return [...this.standardizationTitrationCurve, ...this.unknownTitrationCurve];
    },
  },
};
</script>

<style></style>
